<template>
  <div id="app">
    <div class="background-image" ref="backgroundImage" :style="{ backgroundSize }"></div>
    <header>
      <button @click="toggleMenu" class="hamburger" :class="{ 'open': isMenuOpen }">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </header>
    <nav :class="{ 'open': isMenuOpen }">
      <router-link to="/" @click="closeMenu">home</router-link>
      <router-link to="/about" @click="closeMenu">about us</router-link>
      <router-link to="/projects" @click="closeMenu">projects</router-link>
    </nav>
    <transition :name="transitionName" @enter="onEnter">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isMenuOpen: false,
      transitionName: 'fade',
      prevRoute: null,
      backgroundSize: '100% auto'
    }
  },
  mounted() {
    this.updateBackgroundZoom();
    this.updateBackgroundPosition(this.$route.name);
    window.addEventListener('resize', this.updateBackgroundZoom);
  },
  beforeUnmount() {  // Changed from beforeDestroy to beforeUnmount
    window.removeEventListener('resize', this.updateBackgroundZoom);
  },
  watch: {
    $route(to, from) {
      this.prevRoute = from.name;
      this.transitionName = 'fade';
      this.updateBackgroundPosition(to.name);
      this.updateBackgroundZoom(); // Add this line
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    closeMenu() {
      this.isMenuOpen = false
    },
    onEnter(el, done) {
      el.addEventListener('animationend', done);
    },
    updateBackgroundPosition(routeName) {
      const backgroundImage = this.$refs.backgroundImage;
      if (routeName === 'HomeView') {
        backgroundImage.style.backgroundPosition = 'center top';
      } else {
        const verticalOffset = ((window.innerWidth - 50) > window.innerHeight) ? '-35vh' : '-20vh';
        backgroundImage.style.backgroundPosition = 'center ' + verticalOffset;
      }
      backgroundImage.classList.add('animate-background');
      setTimeout(() => {
        backgroundImage.classList.remove('animate-background');
      }, 500);
      this.updateBackgroundZoom(); // Add this line
    },
    updateBackgroundZoom() {
      if ((window.innerWidth - 100) > window.innerHeight) {
        // Landscape orientation
        this.backgroundSize = '100vw auto';
      } else {
        // Portrait orientation
        this.backgroundSize = 'auto 140vh';
      }
    }
  }
}
</script>

<style>
/* Add global styles here */
body, html, div, p, h1, h2, h3, h4, h5, h6 {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  height: 100%;
}

.hamburger {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0; /* Add this line to remove any default padding */
}

.hamburger span {
  display: block;
  width: 30px;
  height: 3px;
  margin-bottom: 5px;
  background-color: white;
  transition: all 0.3s ease;
}

.hamburger.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -7px);
}

nav {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 100px;
  transition: left 0.3s ease;
}

nav.open {
  left: 0;
}

nav a {
  color: white;
  text-decoration: none;
  font-size: 55px;
  margin: 20px 0;
  padding-left: 20px;
  font-weight: 400;
  width: 100%;
  /* Removed font-family declaration as it's now inherited from body */
}

main {
  height: 100%;
}

@media (max-width: 768px) {
  nav {
    left: -100%;
    width: 100%;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/img/miniature-garden-hero.jpg');
  background-repeat: no-repeat;
  transition: background-position 0.5s ease-out, background-size 0.5s ease-out;
  z-index: -1;
}

.animate-background {
  transition: background-position 0.5s ease-out, background-size 0.5s ease-out;
}

/* Remove previous animation keyframes as they're no longer needed */
</style>
