<template>
    <div class="projects">
      <div class="content">
        <h1>projects</h1>
        <p>Everyone loves a good mystery. Check back later to find out what we're up to.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ProjectsView'
  }
  </script>
  
  <style scoped>
  .projects {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 25vh;
  }

  .content {
    background-color: white;
    padding: 40px;
    max-width: 800px;
    width: 100%;
    border-radius: 10px;
  }
  
  h1 {
    font-size: 36px;
    margin-top: 0;
  }
  </style>