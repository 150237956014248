<template>
    <div class="about">
      <div class="content">
        <h1>about us</h1>
        <p>Community Garden is a next-generation production company, telling compelling stories through feature films and series, leveraging novel production techniques and new technologies to make beautiful art in cost-optimized ways.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutView'
  }
  </script>
  
  <style scoped>
  .about {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 25vh;
  }
  
  .content {
    background-color: white;
    padding: 40px;
    max-width: 800px;
    width: 100%;
    border-radius: 10px;
  }
  
  h1 {
    font-size: 36px;
    margin-top: 0;
  }
  
  p {
    font-size: 18px;
  }
</style>