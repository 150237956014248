import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/Home.vue'
import AboutView from '@/views/About.vue'
import ProjectsView from '@/views/Projects.vue'

const routes = [
  { path: '/', name: 'HomeView', component: HomeView },
  { path: '/about', name: 'AboutView', component: AboutView },
  { path: '/projects', name: 'ProjectsView', component: ProjectsView }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router