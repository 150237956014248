<template>
    <div class="home">
      <div class="content">
        <!-- Add content for the home page here if needed -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomeView'
  }
  </script>
  
  <style scoped>
  .home {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 25vh;
  }

  .content {
    max-width: 800px;
    width: 100%;
  }
  </style>